<template>
  <div class="has-nav-bar view-workticket-detail">
    <van-nav-bar
      title="JSA分析"
      fixed
      left-arrow
      @click-left="$router.back()"
    />
    <div>
      <van-collapse v-model="activeName" class="van-collapse__cell">
        <JSADetailBaseInfo :jsa-data="jsaData" />
        <JSADetailWorkStep :jsa-data="jsaData" />
        <JSADetailAuditRecord :jsa-data="jsaData" />
      </van-collapse>
    </div>
  </div>
</template>

<script>
import JSADetailBaseInfo from "./JSADetailBaseInfo";
import JSADetailWorkStep from "./JSADetailWorkStep";
import JSADetailAuditRecord from "./JSADetailAuditRecord";
import { getJsaInfo } from "@/api/psm/jsa";

export default {
  name: "JSADetail",
  components: {
    JSADetailBaseInfo,
    JSADetailWorkStep,
    JSADetailAuditRecord
  },
  data() {
    return {
      ticketId: this.$route.params.id,
      jsaId: this.$route.params.jsaId,
      activeName: ["workStep"],
      jsaData: {}
    };
  },
  computed: {},
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getJsaInfo();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  methods: {
    async getJsaInfo() {
      const data = await getJsaInfo(this.jsaId);
      const records = data.assessmentJsaVerifyRecords;
      data.assessmentJsaVerifyRecords = (Array.isArray(records)
        ? records
        : []
      ).map(item => {
        item.isShowAllDesc = false;
        return item;
      });
      this.jsaData = data;
    }
  }
};
</script>

<style lang="scss" scoped></style>
