<template>
  <van-collapse-item
    class="block-space_8_notLast work-step-main"
    title="作业步骤"
    name="workStep"
    :border="false"
    :is-link="false"
  >
    <van-collapse v-model="activeNames" class="van-collapse__cell">
      <van-collapse-item name="index" :border="false" :disabled="true">
        <template #title>
          <div class="title-idx text-style gray-text">序号</div>
          <div class="title-content text-style gray-text">内容</div>
        </template>
        <template #value>
          <div class="text-style value-box gray-text">分类</div>
        </template>
      </van-collapse-item>
      <van-collapse-item
        v-for="(step, idx) of jsaData.assessmentJsaSteps"
        :key="step.id"
        :name="idx"
      >
        <template #title>
          <div class="title-idx text-style">{{ idx + 1 }}</div>
          <div class="title-content text-style">{{ step.stepContent }}</div>
        </template>
        <template #value>
          <div class="text-style value-box">{{ step.stepTypeName }}</div>
        </template>
        <template #default>
          <div
            v-for="analyses of step.assessmentJsaAnalyses"
            :key="analyses.id"
            class="open-content"
          >
            <p class="title row">
              【{{ analyses.hazardFactorTypeName }}】{{ analyses.hazardFactor }}
            </p>
            <p class="row">
              <span class="text-style">可能发生的事故类型:</span>
              <span
                v-for="(event, eIdx) of analyses.eventTypeName"
                :key="`sg${eIdx}`"
                class="event-tag"
              >
                {{ event }}
              </span>
            </p>
            <p class="row">
              <span class="text-style">风险评价:</span>
              <span
                v-for="(riskProp, rIdx) of riskEvaluationData(
                  jsaData.evaluationMethod
                )"
                :key="`fx${rIdx}`"
                class="event-tag"
              >
                {{ analyses[riskProp] }}
              </span>
            </p>
            <p class="row">
              <span class="text-style">风险等级:</span>
              <span class="text-style gray-text">
                {{ riskLevelSwitch(jsaData.evaluationMethod, analyses) }}
              </span>
            </p>
            <p class="row">
              <span class="text-style">管理措施:</span>
              <span class="text-style gray-text">
                {{ analyses.managementMeasures }}
              </span>
            </p>
            <p class="row">
              <span class="text-style">培训教育:</span>
              <span class="text-style gray-text">
                {{ analyses.trainingEducation }}
              </span>
            </p>
            <p class="row">
              <span class="text-style">个体防护:</span>
              <span class="text-style gray-text">
                {{ analyses.personalProtection }}
              </span>
            </p>
            <p class="row">
              <span class="text-style">应急处置:</span>
              <span class="text-style gray-text">
                {{ analyses.emergencyResponse }}
              </span>
            </p>
            <p class="row">
              <span class="text-style">可否接受:</span>
              <span class="text-style gray-text">
                {{ analyses.acceptStatusName }}
              </span>
            </p>
            <p class="row">
              <span class="text-style">管控层级:</span>
              <span class="text-style gray-text">
                {{ analyses.governanceLevelName }}
              </span>
            </p>
          </div>
        </template>
      </van-collapse-item>
    </van-collapse>
  </van-collapse-item>
</template>

<script>
export default {
  name: "JSADetailWorkStep",
  props: {
    jsaData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      activeNames: [],
      riskPropMap: {
        0: ["lecL", "lecE", "lecC", "lecD"],
        1: ["lsL", "lsS", "lsR"]
      }
    };
  },
  computed: {},
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  methods: {
    riskEvaluationData(method) {
      return this.riskPropMap[method] || [];
    },
    riskLevelSwitch(method, analyses) {
      let text = "";
      const { lecD, lsR } = analyses;
      if (method === "0") {
        if (lecD > 320) text = "极其危险";
        if (lecD >= 160) text = "高度危险";
        if (lecD >= 70) text = "显著危险";
        else text = "轻度危险";
        return text;
      }
      if (lsR >= 20) text = "极其危险";
      if (lsR >= 15) text = "高度危险";
      if (lsR >= 9) text = "显著危险";
      else text = "轻度危险";
      return text;
    }
  }
};
</script>

<style lang="scss">
.work-step-main {
  .van-collapse__cell {
    .van-collapse-item__title {
      height: auto;
      align-items: initial;
      .van-cell__value {
        -webkit-box-flex: initial;
        -webkit-flex: initial;
        flex: initial;
      }
    }
    .van-collapse-item__title::before {
      content: "";
    }
  }
  .van-collapse__cell div:first-child {
    .van-collapse-item__title {
      height: 30px;
      background: #eceef2;
      padding-top: 6px;
      padding-bottom: 4px;
      .van-icon-arrow::before {
        content: "";
      }
    }
  }
}
</style>

<style lang="scss" scope>
.work-step-main {
  // .center {
  //   text-align: center;
  // }
  .van-collapse__cell {
    .van-collapse-item__title {
      .van-cell__title {
        .title-idx {
          display: inline-block;
          width: 60px;
          vertical-align: top;
        }
        .title-content {
          display: inline-block;
          width: calc(100% - 60px);
          vertical-align: top;
          word-break: break-all;
        }
      }
      .value-box {
        width: 60px;
      }
      .van-cell__right-icon {
        margin-top: -2px;
      }
    }
    .text-style {
      font-size: 14px;
      font-weight: 400;
      color: #2e2e4d;
      line-height: 20px;
    }
    .gray-text {
      color: #8c8f97;
    }
    .open-content {
      margin: 0 16px 16px;
      padding: 16px 8px 0 8px;
      background-color: rgba(22, 118, 255, 0.05);
      .row {
        margin: 0 0 24px;
      }
      .title {
        text-align: center;
        font-weight: 500;
        color: #8c8f97;
        line-height: 20px;
      }
      .event-tag {
        background: #eaeff5;
        border: 1px solid #d8d8d8;
        border-radius: 2px;
        padding: 2px 6px;
        margin: 0 4px;
        font-size: 12px;
        font-weight: 400;
        text-align: justify;
        color: #8c8f97;
        line-height: 20px;
      }
    }
  }
}
</style>
