<template>
  <van-collapse-item
    class="block-space_8_notLast base-info-main"
    title="基本信息"
    name="baseInfo"
    :border="false"
    :is-link="false"
  >
    <van-cell title="JSA名称" :value="jsaData.jsaName" />
    <van-cell
      v-if="Array.isArray(jsaData.workTypeName) && jsaData.workTypeName.length"
      title="作业类型"
    >
      <template #label>
        <Tag
          v-for="(item, index) in jsaData.workTypeName"
          :key="index"
          type="gray"
          :border="false"
          >{{ item }}
        </Tag>
      </template>
    </van-cell>
    <van-cell title="区域/工艺过程" :value="jsaData.poiName" />
    <van-cell title="责任部门" :value="jsaData.deptName" />
    <van-cell title="分析组长" :value="jsaData.groupLeaderName" />
    <van-cell title="成员">
      <template>
        {{ jsaData.groupMemberName ? jsaData.groupMemberName.toString() : "" }}
      </template>
    </van-cell>
    <van-cell title="评价方法" :value="jsaData.evaluationMethodName" />
    <van-cell title="作业内容简述">
      <template #label>
        <p class="work-content">{{ jsaData.workContent }}</p>
      </template>
    </van-cell>
    <van-cell title="更新人" :value="jsaData.uuserName" />
    <van-cell title="更新时间" :value="jsaData.utime" />
  </van-collapse-item>
</template>

<script>
export default {
  name: "JSADetailBase",
  props: {
    jsaData: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
.base-info-main {
  .work-content {
    color: #8c8f97;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
  }
}
</style>
