<template>
  <van-collapse-item
    class="block-space_8_notLast audit-record-main"
    title="审核记录"
    name="auditRecord"
    :border="false"
    :is-link="false"
  >
    <div class="step-box">
      <van-steps direction="vertical" :active="active" active-color="#1676FF">
        <van-step
          v-for="(item, index) in jsaData.assessmentJsaVerifyRecords"
          :key="item.id"
        >
          <template #inactive-icon>
            <i class="inactive-icon"></i>
          </template>
          <template #active-icon>
            <i class="active-icon"></i>
          </template>

          <div class="process-item">
            <div class="title-line">
              <span class="right">{{ operatingSwitch(item) }}</span>
            </div>
            <div class="desc" :class="{ 'initial-height': item.isShowAllDesc }">
              {{ item.remark }}
              <span
                class="ellipsis-box show-all-btn"
                @click="showDescClick(true, index)"
                >展开</span
              >
              <span class="after"></span>
            </div>
            <p v-show="item.isShowAllDesc" class="packup-box">
              <span class="show-all-btn" @click="showDescClick(false, index)"
                >收起</span
              >
            </p>
            <p class="time">
              <span class="icon-item"
                ><i
                  :style="
                    `background-image: url(${require('@/assets/images/workticket-item-5.png')})`
                  "
                ></i
                >{{ item.cuserName }}</span
              >
              <span class="icon-item"
                ><i
                  :style="
                    `background-image: url(${require('@/assets/images/workticket-item-3.png')})`
                  "
                ></i
                >{{ item.ctime }}</span
              >
            </p>
          </div>
        </van-step>
      </van-steps>
    </div>
  </van-collapse-item>
</template>

<script>
export default {
  name: "JSADetailAuditRecord",
  components: {},
  props: {
    jsaData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      // active: 0,
    };
  },
  computed: {
    active() {
      const arr = this.jsaData.assessmentJsaVerifyRecords;
      return Array.isArray(arr) ? arr.length - 1 : 0;
    }
  },
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  methods: {
    showDescClick(show, idx) {
      this.jsaData.assessmentJsaVerifyRecords[idx].isShowAllDesc = show;
    },
    operatingSwitch({ flag, verifyStatus }) {
      if (flag === "0") return "提交";
      if (verifyStatus === "0") return "审核不通过";
      if (verifyStatus === "1") return "审核通过";
      return "";
    }
  }
};
</script>

<style lang="scss">
.audit-record-main {
  .inactive-icon {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #c3c6d0;
    border-radius: 50%;
  }
  .van-step--finish .inactive-icon {
    background-color: $--color-primary;
  }
  .active-icon {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #ffffff;
    border: 2px solid $--color-primary;
    border-radius: 50%;
  }
}
</style>

<style lang="scss" scoped>
.audit-record-main {
  .step-box {
    padding: 0 20px 24px;
  }
  .process-item {
    font-size: 12px;
    color: $--color-gray-item-text;

    .title-line {
      display: flex;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 5px;
      color: $--color-black-title;

      .title {
        flex-grow: 1;
      }
    }

    .initial-height {
      max-height: initial !important;
    }

    .desc {
      max-height: 54px;
      font-size: 12px;
      font-weight: 400;
      color: #8c8f97;
      line-height: 18px;
      margin-bottom: 8px;
      overflow: hidden;
      position: relative;
      word-break: break-all;
      .ellipsis-box {
        text-align: right;
        width: 54px;
        position: absolute;
        right: 0px;
        bottom: 0;
        background: #fff;
        background: -webkit-gradient(
          linear,
          270deg,
          #ffffff,
          #ffffff 46%,
          rgba(255, 255, 255, 0)
        );
        background: -moz-linear-gradient(
          to right,
          270deg,
          #ffffff,
          #ffffff 46%,
          rgba(255, 255, 255, 0)
        );
        background: -o-linear-gradient(
          to right,
          270deg,
          #ffffff,
          #ffffff 46%,
          rgba(255, 255, 255, 0)
        );
        background: -ms-linear-gradient(
          to right,
          270deg,
          #ffffff,
          #ffffff 46%,
          rgba(255, 255, 255, 0)
        );
        background: linear-gradient(
          270deg,
          #ffffff,
          #ffffff 46%,
          rgba(255, 255, 255, 0)
        );
      }
      .after {
        height: 18px;
        width: 54px;
        position: absolute;
        right: 0px;
        background-color: white;
      }
    }

    .show-all-btn {
      color: #1676ff;
    }
    .packup-box {
      text-align: right;
      margin-top: -8px;
    }

    .icon-item {
      display: inline-block;
      margin-right: 20px;

      i {
        display: inline-block;
        width: 15px;
        height: 15px;
        vertical-align: top;
        margin-right: 5px;
        background-size: cover;
      }
    }
  }
}
</style>
